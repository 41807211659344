






































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import { invalidRegisterRecipientOptions, recipientRuleDescriptor } from '@/data/RegisterRecipientFormData'
import {
  IInvalidRecipientPresentation,
  InvalidRecipientPresentation
} from '@/presentation/recipient/InvalidRecipientPresentation'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { ManageTable }
})
export default class Register extends Vue {
  @Getter getRegisterRecipients: any
  @Mutation setValidatedRecipients: any
  @Mutation disableLoading: any

  initialized: boolean = false
  presentation: IInvalidRecipientPresentation = new InvalidRecipientPresentation()
  totalInvalidCount: Dictionary<number> = {}
  tableOption: Dictionary<ManageTableOptions> = invalidRegisterRecipientOptions
  descriptor: Dictionary<any> = recipientRuleDescriptor

  getCountryMethodLabel(countryWithMethod: string): string {
    return this.presentation.getCountryMethodLabel(countryWithMethod)
  }

  async toggleUpdatable(countryWithMethod: string, index: number, callback?: any): Promise<void> {
    await this.presentation.toggleUpdatable(countryWithMethod, index, callback)
  }

  deleteRow(countryWithMethod: string, index: number) {
    this.presentation.deleteRow(countryWithMethod, index)
  }

  register() {
    if (!this.presentation.hasInvalidRecipients) {
      const recipients = this.presentation.register()
      this.setValidatedRecipients(recipients)
      this.$router.push('register')
    }
  }

  handleTabClick(): void {
    this.presentation.page = 1
  }

  async created() {
    const uploadedRecipients = this.getRegisterRecipients
    await this.presentation.initialize(uploadedRecipients)
    this.initialized = true
    this.disableLoading()
    if (!this.presentation.hasAllInvalidCounts) {
      this.$message.success(`${this.$t('notification.all_info_valid')}`)
      this.register()
    }
  }
}
